html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  box-sizing: 'border-box';
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  min-height: '100vh';
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: 'inherit';
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}
